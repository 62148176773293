import React from 'react'
import { BsLinkedin, BsGithub, BsTwitter } from 'react-icons/bs'
const HeaderSocial = () => {
  return (
    <div className='header__socials'>
      <a href="https://www.linkedin.com/in/rajan-sharma-99154a183/" rel="noreferrer" target="_blank"><BsLinkedin /></a>
      <a href="https://github.com/rajan4405" rel="noreferrer" target="_blank"><BsGithub /></a>
      <a href="https://twitter.com/quickcoder_" rel="noreferrer" target="_blank"><BsTwitter /></a>
    </div>
  )
}

export default HeaderSocial